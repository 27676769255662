export const serviceData = [
    {
      id:0,
      name:"Лендинг",
    },
    {
      id:1,
      name:"Бизнес сайт",
    },
    {
      id:2,
      name:"Интернет магазин",
    },
  ]
